import React, { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import logoDark from '../../assets/images/resources/logo-dark.png';
import logoLight from '../../assets/images/resources/logo-light.png';
import { NavLink, PageNavLinks, NavStyle, ActionButtonType } from '../../types';

const NAVIGATION_LINKS: NavLink[] = [
  {
    link: '/',
    title: 'Just Pick Something for Everyone',
    text: 'For consumers',
  },
  {
    link: '/businesses',
    title: 'Just Pick Something for Businesses',
    text: 'For businesses',
  },
];

interface HeaderProps extends RouteComponentProps {
  pageNavLinks?: PageNavLinks;
  navStyle?: NavStyle;
  actionButtonType?: ActionButtonType;
}

interface HeaderState {
  sticky: boolean;
}

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      sticky: false,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
    this.mobileMenu();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll = (): void => {
    if (window.scrollY > 70) {
      this.setState({
        sticky: true,
      });
    } else if (window.scrollY < 70) {
      this.setState({
        sticky: false,
      });
    }
  };

  private handleScrollTop = (): void => {
    window.scrollTo(0, 0);
  };

  private mobileMenu = (): void => {
    // Mobile Menu Toggle
    const mainNavToggler = document.querySelector('.menu-toggler');
    const mainNav = document.querySelector('.main-navigation') as HTMLElement;

    mainNavToggler?.addEventListener('click', function mainNavTogglerClick() {
      if (mainNav) {
        mainNav.style.display = mainNav?.style.display !== 'block' ? 'block' : 'none';
      }
    });
  };

  private renderDownloadButton(): React.ReactNode {
    const { location } = this.props;
    let downloadButton;

    if (location.pathname === '/') {
      downloadButton = (
        <a
          href="#download"
          title="Download Just Pick Something"
          className="thm-btn header__cta-btn"
        >
          <span>Download</span>
        </a>
      );
    } else {
      downloadButton = (
        <Link to="/" title="Download Just Pick Something" className="thm-btn header__cta-btn">
          <span>Download</span>
        </Link>
      );
    }

    return downloadButton;
  }

  private renderSignUpButton(): React.ReactNode {
    return (
      <a
        href="#pricing"
        title="Sign-up for Just Pick Something"
        className="thm-btn header__cta-btn"
      >
        <span>Sign up</span>
      </a>
    );
  }

  private renderActionButton(): React.ReactNode {
    const { actionButtonType } = this.props;
    let actionButton;

    if (actionButtonType === 'download') {
      actionButton = this.renderDownloadButton();
    } else if (actionButtonType === 'sign-up') {
      actionButton = this.renderSignUpButton();
    }

    return actionButton;
  }

  public render(): React.ReactNode {
    const { pageNavLinks = [], navStyle, location } = this.props;
    const { sticky } = this.state;

    const combinedNavLinks = NAVIGATION_LINKS.concat(pageNavLinks);

    const logo = navStyle === 'dark' ? logoLight : logoDark;

    const containerClasses = classNames('site-header site-header__header-one', {
      'site-header__header-two': navStyle === 'dark',
    });

    const navClasses = classNames(
      'navbar navbar-expand-lg navbar-light header-navigation stricky',
      {
        'stricked-menu stricky-fixed': sticky,
      },
    );

    return (
      <header className={containerClasses}>
        <nav className={navClasses}>
          <div className="container clearfix">
            <div className="logo-box clearfix">
              <Link to="/" className="navbar-brand">
                <img src={logo} className="main-logo" width="119" alt="Just Pick Something" />
              </Link>
              <button type="button" className="menu-toggler">
                <span className="fas fa-bars" />
              </button>
            </div>
            <div className="main-navigation">
              <ul className="one-page-scroll-menu navigation-box">
                {combinedNavLinks.map(navLink => {
                  const navLinkPath =
                    typeof navLink.link === 'object' ? (navLink.link as any).to : navLink.link;
                  const isCurrentPage = navLinkPath.includes('#')
                    ? location.hash === navLinkPath
                    : location.pathname === navLinkPath && !location.hash;
                  const classes = classNames('scrollToLink', {
                    current: isCurrentPage,
                  });

                  return (
                    <li key={navLink.text} className={classes}>
                      {navLink.tag === 'normal' ? (
                        <a href={navLink.link as string} title={navLink.title ?? navLink.text}>
                          {navLink.text}
                        </a>
                      ) : (
                        <Link
                          to={navLink.link}
                          title={navLink.title ?? navLink.text}
                          onClick={() => {
                            if (location.pathname === navLinkPath) {
                              this.handleScrollTop();
                            }
                          }}
                        >
                          {navLink.text}
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="right-side-box">{this.renderActionButton()}</div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
