import React from 'react';
import Analytics, { EVENTS } from '../../analytics';
import { Stats } from '../../components';
import { StatsList } from '../../types';

const STATS: StatsList = [
  {
    start: 999000,
    end: 1000000,
    text: 'Restaurants in the United States',
    duration: 120,
  },
  {
    start: 800,
    end: 899,
    text: 'Billion projected for 2020',
    duration: 120,
    prefix: '$',
  },
  {
    start: 0,
    end: 63,
    text: 'of consumers want to visit your location',
    duration: 360,
    suffix: '%',
  },
  {
    start: 0,
    end: 59,
    text: 'close their door after 3 years',
    duration: 30,
    suffix: '%',
  },
];

const BusinessStats: React.FC<{}> = () => {
  function renderSourceLink(): React.ReactNode {
    return (
      <a
        href="http://www.restaurant.org/News-Research/Research/Facts-at-a-Glance"
        title="Restaurant statistics"
        onClick={() => {
          Analytics.logEvent(EVENTS.CLICK_EXTERNAL_LINK, {
            to: 'http://www.restaurant.org/News-Research/Research/Facts-at-a-Glance',
            text: 'Source for statistics',
            style: 'link',
            source: 'business stats',
          });
        }}
      >
        Source for statistics
      </a>
    );
  }

  return <Stats stats={STATS} statisticsSource={renderSourceLink()} />;
};

export default BusinessStats;
