import React from 'react';
import Analytics, { EVENTS } from '../../analytics';
import { Stats } from '../../components';
import { StatsList } from '../../types';

const STATS: StatsList = [
  {
    start: 999000,
    end: 1000000,
    text: 'Restaurants in the United States',
    duration: 120,
  },
  {
    start: 199000,
    end: 200000,
    text: 'Concerts per year',
    duration: 120,
  },
  {
    start: 0,
    end: 1000000,
    text: 'Hours spent researching',
    duration: 360,
    suffix: '+',
  },
  {
    start: 0,
    end: 50,
    text: 'Restaurants you can name',
    duration: 30,
  },
];

const ConsumerStats: React.FC<{}> = () => {
  function renderSourceLink(): React.ReactNode {
    return (
      <a
        href="http://www.restaurant.org/News-Research/Research/Facts-at-a-Glance"
        title="Restaurant statistics"
        onClick={() => {
          Analytics.logEvent(EVENTS.CLICK_EXTERNAL_LINK, {
            to: 'http://www.restaurant.org/News-Research/Research/Facts-at-a-Glance',
            text: 'Source for statistics',
            style: 'link',
            source: 'home stats',
          });
        }}
      >
        Source for statistics
      </a>
    );
  }

  return <Stats stats={STATS} statisticsSource={renderSourceLink()} />;
};

export default ConsumerStats;
