import '@fortawesome/fontawesome-free/css/all.min.css';
import 'aos/dist/aos.css';
import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import AOS from 'aos';
import ScrollToTop from './ScrollToTop';
import consumerPageRoutes from './consumer';
import businessPageRoutes from './business';
import legalRoutes from './legal';
import helpRoutes from './help';
import aboutRoutes from './about';
import Error404 from './errors/404';
import ErrorWrapper from './errors';

AOS.init();

const App: React.FunctionComponent = () => (
  <>
    <Helmet titleTemplate="%s - Just Pick Something" defaultTitle="Just Pick Something App">
      <meta
        name="description"
        content="Discover new restaurants, bars and experiences in your city - fast, simple, easy"
      />
      <meta
        name="keywords"
        content="just pick something, jps, app, download, learn, discover, city, restaurants, bars, concerts, experiences, sports, tickets"
      />
    </Helmet>
    <Router>
      <ErrorWrapper withHeader>
        <ScrollToTop />
        <Switch>
          {consumerPageRoutes}
          {businessPageRoutes}
          {legalRoutes}
          {helpRoutes}
          {aboutRoutes}
          <Route component={Error404} />
        </Switch>
      </ErrorWrapper>
    </Router>
  </>
);

export default App;
