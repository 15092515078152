import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { SectionProps, StatsList } from '../types';

interface StatsProps extends SectionProps {
  stats: StatsList;
  statisticsSource?: React.ReactNode;
}

interface StatsState {
  startCounter: boolean;
}

export default class Stats extends Component<StatsProps, StatsState> {
  constructor(props: StatsProps) {
    super(props);

    this.state = {
      startCounter: false,
    };
  }

  private onVisibilityChange = (isVisible: boolean): void => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  public render(): React.ReactNode {
    const { stats, sectionId, statisticsSource } = this.props;
    const { startCounter } = this.state;

    return (
      <section className="fact-one" id={sectionId}>
        <div className="container">
          <div className="block-title text-center">
            <h2 className="block-title__title">
              Are you a&nbsp;
              <span>numbers</span>
              &nbsp;person?
            </h2>
          </div>
          <div className="row">
            {stats.map(stat => (
              <div
                key={stat.text}
                className="col-lg-3 col-md-6 col-sm-12"
                data-aos="fade-up"
                data-aos-duration="1500ms"
              >
                <div className="fact-one__single">
                  <div className="fact-one__inner">
                    <h3 className="fact-one__count counter">
                      <VisibilitySensor
                        onChange={this.onVisibilityChange}
                        offset={{ top: 10 }}
                        delayedCall
                      >
                        <CountUp
                          end={startCounter ? stat.end : 0}
                          start={stat.start ?? 0}
                          separator=","
                          duration={stat.duration}
                          prefix={stat.prefix}
                          suffix={stat.suffix}
                        />
                      </VisibilitySensor>
                    </h3>
                    <p className="fact-one__text">{stat.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {statisticsSource && <p className="text-center mt-4">{statisticsSource}</p>}
        </div>
      </section>
    );
  }
}
