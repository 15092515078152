import React from 'react';
import HubspotForm from 'react-hubspot-form';
import Analytics, { EVENTS } from '../../analytics';
import { PricingPlanType } from '../../types';

const PlanDetails = {
  [PricingPlanType.SELF_MANAGE]: {
    portalId: '4271709',
    formId: '9c9447aa-a84a-4744-af96-ec54fcd268f2',
  },
  [PricingPlanType.ULTIMATE]: {
    portalId: '4271709',
    formId: '921ec705-ba00-4339-a745-417793835d61',
  },
} as const;

interface BusinessPlanSignupProps {
  planType: PricingPlanType;
}

const BusinessPlanSignup: React.FunctionComponent<BusinessPlanSignupProps> = ({ planType }) => (
  <div id={`business-plan-signup--${planType}`} className="pt-4 px-2">
    <p>
      Thank you for your interest in working with Just Pick Something!
      <br />
      Fill out the form below and we will reach out with the next steps.
    </p>
    <HubspotForm
      {...(PlanDetails as any)[planType]}
      onSubmit={() => Analytics.logEvent(EVENTS.BUSINESS_PRICING_SIGNUP_SUBMIT)}
    />
  </div>
);

export default BusinessPlanSignup;
