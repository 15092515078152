import React from 'react';
import { Route } from 'react-router-dom';
import TermsConditions from './TermsConditions';
import PrivacyPolicy from './PrivacyPolicy';

const legalRoutes = [
  <Route key="terms" path="/terms" exact component={TermsConditions} />,
  <Route key="privacy" path="/privacy" exact component={PrivacyPolicy} />,
];

export default legalRoutes;
