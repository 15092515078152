import React from 'react';
import BannerMoc from '../../assets/images/mocs/banner-moc-1-1.png';

const Banner: React.FC<{}> = () => {
  return (
    <section className="banner-one" id="banner">
      <span className="banner-one__shape-1" />
      <span className="banner-one__shape-2" />
      <span className="banner-one__shape-3" />
      <span className="banner-one__shape-4" />
      <div className="container">
        <div className="banner-one__moc">
          <img src={BannerMoc} alt="Screenshot of Just Pick Something App" />
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-one__content">
              <h3 className="banner-one__title">
                It is time to&nbsp;
                <span>discover</span>
                &nbsp;new and exciting experiences made just for you
              </h3>
              <p className="banner-one__text">
                With Just Pick Something, it is finally possible to discover unique drinks,
                flavorful food, and amazing entertainment that won&apos;t disappoint.
              </p>
              <a
                href="#download"
                title="Download Just Pick Something"
                className="banner-one__btn thm-btn "
              >
                <span>Download & Start Discovering!</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
