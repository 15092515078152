/* global branch */

class Branch {
  constructor() {
    const branchId = process.env.REACT_APP_BRANCH;

    if (branchId) {
      branch.init(branchId);
    }
  }
}

export default new Branch();
