import React from 'react';
import Helmet from 'react-helmet';
import { PageNavLinks } from '../types';
import Banner from './sections/BusinessBanner';
import Features from './sections/BusinessFeatures';
import CallToAction from './sections/BusinessCallToAction';
import CallToActionTwo from './sections/BusinessCallToActionTwo';
import Pricing from './sections/BusinessPricing';
import Stats from './sections/BusinessStats';
import { Layout, Newsletter } from '../components';

const NAV_LINKS: PageNavLinks = [
  {
    link: '#features',
    title: 'Features of Just Pick Something',
    text: 'Features',
    tag: 'normal',
  },
  {
    link: '#pricing',
    title: 'Just Pick Something Cost',
    text: 'Pricing',
    tag: 'normal',
  },
];

const ConsumerLandingPage: React.FC<{}> = () => (
  <Layout pageNavLinks={NAV_LINKS} navStyle="dark" actionButtonType="sign-up">
    <Helmet>
      <title>Just Pick Something for Businesses</title>
      <meta
        name="description"
        content="Never let your business fall behind competitors when you partner with Just Pick Something"
      />
      <meta
        name="keywords"
        content="just pick something, jps, businesses, companies, consumer analytics, consumer trends, restaurant success, bar success"
      />
    </Helmet>
    <Banner />
    <Features />
    <CallToAction />
    <CallToActionTwo />
    <Pricing />
    <Stats />
    <Newsletter source="business" />
  </Layout>
);

export default ConsumerLandingPage;
