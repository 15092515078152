import React from 'react';
import mocImage from '../../assets/images/mocs/cta-moc-1-1.png';
import { CallToAction } from '../../components';
import { FeatureList } from '../../types';

const FEATURE_LIST: FeatureList = [
  {
    icon: 'heart',
    title: 'It is like dating your customers',
    text:
      'Our advanced matching helps bring in not just any customer - but the customers you really want. Your location has a unique feel and atmosphere, we are here to enhance it, not destory it.',
  },
  {
    icon: 'comments',
    title: 'Consumer trends data',
    text:
      'We are here to help you understand the community you built your business around. Let us notify you when we start seeing more consumers wanting Karaoke instead of Trivia night!',
  },
  {
    icon: 'trophy',
    title: 'Competitive advantage',
    text:
      'The hospitality industry is one of the most competive ones to get into. That is why we take passion in helping you become successful with actual data-driven recommendations.',
  },
  {
    icon: 'ticket-alt',
    title: 'Event management',
    text:
      'We allow you to easily manage your events and even help you keep them in sync with other major platforms like Facebook.',
  },
];

const BusinessCallToAction: React.FC<{}> = () => (
  <CallToAction
    image={mocImage}
    imageAltText=""
    sectionTitle={(
      <>
        Buidling what is&nbsp;
        <span>useful</span>
        &nbsp;for your company
      </>
    )}
    sectionText="Here is a snapshot of some of our current features and there is more to come! We are alwys working closely with our business family to know exactly what you and your team needs."
    features={FEATURE_LIST}
    callToAction={{
      link: '#pricing',
      title: 'Sign up my business for Just Pick Someting',
      text: "Let's try this out",
    }}
  />
);

export default BusinessCallToAction;
