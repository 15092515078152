import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ErrorWrapper from './errors';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import './analytics';

ReactDOM.render(
  <ErrorWrapper>
    <App />
  </ErrorWrapper>,
  document.getElementById('root'),
);

registerServiceWorker();
unregister();
