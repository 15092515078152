import React from 'react';
import RModal from 'react-modal';
import './Modal.scss';

const RModalStyling = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 200,
  },
} as const;

interface ModalProps {
  isOpen: boolean;
  onClose(): void;
  contentLabel: string;
  header: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, contentLabel, header, children }) => (
  <RModal
    isOpen={isOpen}
    style={RModalStyling}
    contentLabel={contentLabel}
    shouldCloseOnOverlayClick
  >
    <div className="modal-header">
      <h2>{header}</h2>
      <button type="button" className="modal-close text-muted" onClick={onClose}>
        <i className="fas fa-times" />
      </button>
    </div>
    {children}
  </RModal>
);

export default Modal;
