import React from 'react';
import bgImage from '../../assets/images/background/cta-one-bg.png';
import mocImage from '../../assets/images/mocs/cta-moc-1-1.png';

const CallToAction: React.FC<{}> = () => {
  return (
    <section className="cta-one">
      <img src={bgImage} className="cta-one__bg" alt="" />
      <div className="container">
        <img src={mocImage} className="cta-one__moc" alt="" />
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  What&nbsp;
                  <del>can</del>
                  &nbsp;
                  <span>can&apos;t</span>
                  &nbsp;you discover?
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  Stop wasting your time looking at generic article recommendations and reading
                  through 6 pages of reviews just to find a place you end up hating. We match you
                  with the experience we know you&apos;ll love.
                </p>
              </div>
              <ul className="list-unstyled">
                <li>
                  <i className="fas fa-cocktail" />
                  <h4>Discover unique drinks</h4>
                  <span>
                    Do you know all of the speakeasies in your city? What about where to get the
                    best bloody mary? We do.
                  </span>
                </li>
                <li>
                  <i className="fas fa-utensils" />
                  <h4>Discover flavorful food</h4>
                  <span>
                    What place is open late at night when you have the munchies? Do you know where
                    to find the best burger? We do.
                  </span>
                </li>
                <li>
                  <i className="fas fa-ticket-alt" />
                  <h4>Discover amazing entertainment</h4>
                  <span>
                    Feeling adventurous? If you’re in the mood for more than just dinner and drinks
                    we can help! From pool halls to escape rooms - we know where the fun is.
                  </span>
                </li>
                <li>
                  <i className="fas fa-life-ring" />
                  <h4>Discover cures for bordeom</h4>
                  <span>
                    We also have playlists to help you when you are staying in. From virtual games
                    with friends to what movie to put on tonight.
                  </span>
                </li>
                <li>
                  <i className="fas fa-list-ul" />
                  <h4>More coming soon</h4>
                  <span>
                    We are always listening to your feedback and releasing new playlists for you to
                    have fun discovering.&nbsp;
                    <a href="#newsletter" title="Sign up for our newsletter">
                      To get the latest news, sign up for our newsletter.
                    </a>
                  </span>
                </li>
              </ul>
              <a href="#download" title="Download the best local search app" className="thm-btn">
                <span>I am ready to download!</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
