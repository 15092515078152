import React from 'react';
import bgImage from '../assets/images/background/cta-one-bg.png';
import { FeatureList } from '../types';

interface CallToActionProps {
  image: any;
  imageAltText: string;
  sectionTitle: React.ReactNode;
  sectionText: React.ReactNode;
  features: FeatureList;
  callToAction?: {
    link: string;
    title: string;
    text: string;
  };
}

const CallToAction: React.FC<CallToActionProps> = ({
  image,
  imageAltText,
  sectionTitle,
  sectionText,
  features,
  callToAction,
}) => {
  return (
    <section className="cta-one">
      <img src={bgImage} className="cta-one__bg" alt="" />
      <div className="container">
        <img src={image} className="cta-one__moc" alt={imageAltText} />
        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <i className="cta-one__icon dimon-icon-data1" />
              <div className="block-title text-left">
                <h2 className="block-title__title">{sectionTitle}</h2>
              </div>
              <div className="cta-one__text">
                <p>{sectionText}</p>
              </div>
              <ul className="list-unstyled">
                {features.map(feature => (
                  <li key={feature.title}>
                    <i className={`fas fa-${feature.icon}`} />
                    <h4>{feature.title}</h4>
                    {feature.text && <span>{feature.text}</span>}
                  </li>
                ))}
              </ul>
              {callToAction && (
                <a href={callToAction.link} title={callToAction.title} className="thm-btn">
                  <span>{callToAction.text}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
