import React from 'react';
import Helmet from 'react-helmet';
import Analytics, { EVENTS, PAGES } from '../analytics';
import { SUPPORT_EMAIL } from '../constants';
import { Layout, Newsletter, PageHeader, PageBody } from '../components';

class HelpLandingPage extends React.PureComponent<{}, never> {
  public componentDidMount(): void {
    Analytics.logPageview(PAGES.HELP);
  }

  public render(): React.ReactNode {
    return (
      <Layout navStyle="dark">
        <Helmet>
          <title>Help and Support | Just Pick Something App</title>
          <meta
            name="description"
            content="Get help and support when using Just Pick Something app"
          />
          <meta name="keywords" content="just pick something, jps, help, support" />
        </Helmet>
        <PageHeader title="Always ready to provide help" />
        <PageBody>
          <div className="section-block bkg-blue pt-40 pb-40" />
          <section className="section-block pt-20 pb-20">
            <div className="row">
              <h1>Get some help</h1>
              <p>
                Did you end up having an issue with the Just Pick Something app? Need to voice your
                concerns to the higher-ups? This is the place for it.
              </p>
              <p>
                Feel free to look through the knowledge board to see if it has the answer you need.
                If you need to give your fingers some exercise, reach out to us via email.
              </p>
              <a
                href={SUPPORT_EMAIL.link}
                title={SUPPORT_EMAIL.text}
                className="button bkg-blue-light bkg-hover-blue color-white color-hover-white"
                onClick={() => {
                  Analytics.logEvent(EVENTS.CLICK_EXTERNAL_LINK, {
                    to: SUPPORT_EMAIL.link,
                    text: 'Email for support',
                    style: 'primary button',
                    source: 'help',
                  });
                }}
              >
                <span className="fas fa-envelope" />
                &nbsp;Email for support
              </a>
            </div>
          </section>
        </PageBody>
        <Newsletter source="help" />
      </Layout>
    );
  }
}

export default HelpLandingPage;
