import React from 'react';
import bannerMoc from '../../assets/images/mocs/banner-moc-2-1.png';

const BusinessBanner: React.FC<{}> = () => {
  return (
    <section className="banner-two" id="banner">
      <span className="banner-two__shape-1" />
      <span className="banner-two__shape-2" />
      <span className="banner-two__shape-3" />
      <span className="banner-two__shape-4" />
      <div className="banner-two__moc">
        <img src={bannerMoc} alt="Manage Just Pick Something Locations" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-two__content">
              <h3 className="banner-two__title">
                Helping small businesses&nbsp;
                <span>succeed</span>
                &nbsp;with the data they need
              </h3>
              <p className="banner-two__text">
                We do all of the hard computer science work, you continue doing what has already
                made you great.
              </p>
              <a
                href="#pricing"
                title="Pricing for Just Pick Something"
                className="banner-two__btn thm-btn "
              >
                <span>Skip the boring and get signed up</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessBanner;
