import { PAGES } from '../analytics';

export type NavStyle = 'light' | 'dark';

export interface NavLink {
  link: string | object;
  text: string;
  title?: string;
  tag?: 'normal' | 'react-router';
  analyticsPageName?: PAGES;
}

export type PageNavLinks = NavLink[];

export interface SectionProps {
  sectionId?: string;
}

export interface Stat {
  end: number;
  text: string;
  start?: number;
  duration?: number;
  prefix?: string;
  suffix?: string;
}

export type StatsList = Stat[];

export interface FAQ {
  title: string;
  content: string;
  status?: boolean;
}

export type FAQList = FAQ[];

export interface Feature {
  title: string;
  text?: string;
  icon: string;
  link?: string;
  linkTitle?: string;
}

export type FeatureList = Feature[];

export enum PricingPlanType {
  SELF_MANAGE = 'SELF_MANAGE',
  ULTIMATE = 'ULTIMATE',
}

export interface PricingPlan {
  type: PricingPlanType;
  title: string;
  costMonthly: string;
  costYearly: string;
  savings?: string;
  features: string[];
}

export type PricingPlans = PricingPlan[];

export type Breadcrumb = NavLink;

export type BreadcrumbList = Breadcrumb[];

export type ActionButtonType = 'download' | 'sign-up';
