import React from 'react';
import Analytics, { EVENTS } from '../../analytics';
import { Modal, Pricing } from '../../components';
import { PricingPlans, PricingPlanType, PricingPlan } from '../../types';
import BusinessPlanSignup from './BusinessPlanSignup';

const PRICING_PLANS: PricingPlans = [
  {
    type: PricingPlanType.SELF_MANAGE,
    title: 'Self-managed plan',
    costMonthly: 'Free',
    costYearly: 'Free',
    features: ['Manage up to 5 locations', 'Post up to 5 events/mo', 'Basic consumer data'],
  },
  {
    type: PricingPlanType.ULTIMATE,
    title: 'Ultimate plan',
    costMonthly: '$250',
    costYearly: '$2,550',
    savings: 'Save 15% on month-to-month cost',
    features: [
      'Manage unlimited locations',
      'Post unlimited events/mo',
      'Complex consumer data',
      'Advanced competition data',
    ],
  },
];

interface BusinessPricingState {
  showSignupForm?: {
    plan: PricingPlan;
    period: 'monthly' | 'yearly';
  };
}

class BusinessPricing extends React.PureComponent<{}, BusinessPricingState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      showSignupForm: undefined,
    };
  }

  private handleShowSignupForm = (plan: PricingPlan, period: 'monthly' | 'yearly'): void => {
    Analytics.logEvent(EVENTS.BUSINESS_PRICING_SIGNUP, {
      period,
      price: period === 'monthly' ? plan.costMonthly : plan.costYearly,
      name: plan.title,
      features: plan.features,
    });

    this.setState({
      showSignupForm: {
        plan,
        period,
      },
    });
  };

  private handleCloseSignupForm = (): void => {
    Analytics.logEvent(EVENTS.BUSINESS_PRICING_SIGNUP_CLOSE);

    this.setState({
      showSignupForm: undefined,
    });
  };

  private renderSignupForm(): React.ReactNode {
    const { showSignupForm } = this.state;

    return (
      <Modal
        isOpen={!!showSignupForm}
        onClose={this.handleCloseSignupForm}
        header="Let's setup a business plan"
        contentLabel="Setup a business plan with Just Pick Something"
      >
        {showSignupForm && <BusinessPlanSignup planType={showSignupForm.plan.type} />}
      </Modal>
    );
  }

  public render(): React.ReactNode {
    return (
      <>
        <Pricing
          sectionTitle={(
            <>
              Keeping it&nbsp;
              <strong>simple</strong>
              &nbsp;with two plans
            </>
          )}
          sectionText={
            <>
              <p className="mb-4">
                We are here to provide the most value to consumers and business owners that we can
                without going broke ourselves. That is why our pricing lets you easily start for
                free and only upgrade as your business allows.
              </p>
              <p>
                I bet you are savvy and know how to run your business well. If so, feel free to sign
                up for our self-managed version! If you want more comprehensive data on demands,
                trends and other marketing advantages, just get that upgrade!
              </p>
            </>
          }
          pricingPlans={PRICING_PLANS}
          onPlanSelect={this.handleShowSignupForm}
        />
        {this.renderSignupForm()}
      </>
    );
  }
}

export default BusinessPricing;
