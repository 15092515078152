/* global analytics */

interface ISegment {
  logPageview(pageName: string, details?: object): void;
  logEvent(eventName: string, details?: object): void;
}

class Segment implements ISegment {
  constructor() {
    const isDev = process.env.NODE_ENV === 'development';
    const segmentId = process.env.REACT_APP_SEGMENT;

    if (segmentId) {
      analytics.load(segmentId);
      analytics.identify();
    }

    if (isDev) {
      analytics.debug();
    }
  }

  public logPageview(pageName: string, details?: object): void {
    analytics.page(pageName, details);
  }

  public logEvent(eventName: string, details?: object): void {
    analytics.track(eventName, details);
  }
}

export default new Segment();
