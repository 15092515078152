import React from 'react';
import Error500 from './500';

interface ErrorWrapperProps {
  withHeader?: boolean;
}

interface ErrorWrapperState {
  error?: Error;
}

class ErrorWrapper extends React.PureComponent<ErrorWrapperProps, ErrorWrapperState> {
  constructor(props: ErrorWrapperProps) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  public componentDidCatch(error: Error): void {
    this.setState({
      error,
    });
  }

  public render(): React.ReactNode {
    const { children, withHeader } = this.props;
    const { error } = this.state;
    let rendered = children;

    if (error) {
      console.error(error);
      rendered = <Error500 withHeader={withHeader} />;
    }

    return rendered;
  }
}

export default ErrorWrapper;
