export const APP_DOWNLOAD_URLS = {
  android: {
    link: 'https://play.google.com/store/apps/details?id=com.justpicksomething.justpicksomething',
    text: 'Download Just Pick Something on Android',
  },
  ios: {
    link: 'https://itunes.apple.com/us/app/just-pick-something/id1438593129',
    text: 'Download Just Pick Something on iOS',
  },
};

export const SOCIAL_NETWORK_URLS = {
  facebook: {
    link: 'https://www.facebook.com/justpicksomething',
    text: 'Just Pick Something on Facebook',
  },
  youtube: {
    link: 'https://www.youtube.com/channel/UCKz0RQXLNWAA8WXY_XbrGlg',
    text: 'Just Pick Something on YouTube',
  },
  instagram: {
    link: 'https://www.instagram.com/justpicksomethingapp/',
    text: 'Just Pick Something on Instagram',
  },
  twitter: {
    link: 'https://twitter.com/OfficialJPSApp',
    text: 'Just Pick Something on Twitter',
  },
  linkedin: {
    link: 'https://www.linkedin.com/company/justpicksomething/',
    text: 'Just Pick Something on LinkedIn',
  },
};

export const SUPPORT_EMAIL = {
  link: 'mailto:help@justpicksomethingapp.com',
  email: 'help@justpicksomethingapp.com',
  text: 'Just Pick Something Support',
};

export const MAIN_EMAIL = {
  link: 'mailto:hi@justpicksomethingapp.com',
  email: 'hi@justpicksomethingapp.com',
  text: 'Just Pick Something Email',
};

export const DMCA_EMAIL = {
  link: 'mailto:dmca@justpicksomethingapp.com',
  email: 'dmca@justpicksomethingapp.com',
  text: 'Copyright Claims/DMCA for Just Pick Something',
};
