import React from 'react';
import { Features } from '../../components';
import { FeatureList } from '../../types';

const FEATURES: FeatureList = [
  {
    title: 'The Spotify of everything else',
    text:
      'Spotify is amazing, we all know that. Its Discover Weekly playlist is even more amazing. Now imagine that, but with everything else in life - from where to eat to what to read.',
    icon: 'stream',
  },
  {
    title: 'Got friends?',
    text:
      "So do we and that's why we make it easy to discover experiences that will make everyone happy.",
    icon: 'user-friends',
  },
  {
    title: 'Playlists that always change',
    text:
      'Our playlists adapt to you, the time of day, the weather and more. We try our hardest to anticipate what you are thinking, before you even think it, just to make the next part of your life that much easier.',
    icon: 'sync',
  },
  {
    title: 'We are like a great wine',
    text:
      'We just keep getting better over time. We use your past experiences to make sure your next one is even more amazing.',
    icon: 'wine-glass-alt',
  },
];

const ConsumerFeatures: React.FC<{}> = () => (
  <Features
    sectionTitle={(
      <>
        Making what is&nbsp;
        <span>useful</span>
        &nbsp;for you
      </>
    )}
    features={FEATURES}
  />
);

export default ConsumerFeatures;
