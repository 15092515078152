import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Analytics, { PAGES } from '../analytics';
import { Layout, PageBody } from '../components';

class Error404 extends React.PureComponent<{}, never> {
  public componentDidMount(): void {
    Analytics.logPageview(PAGES.ERROR_404);
  }

  public render(): React.ReactNode {
    return (
      <Layout>
        <Helmet>
          <title>Error page</title>
          <meta
            name="description"
            content="This page you are looking for is missing for Just Pick Something"
          />
          <meta name="keywords" content="just pick something, jps, error page, 404 error" />
          <meta httpEquiv="Status" content="404 Not Found" />
        </Helmet>
        <PageBody noBanner>
          <div className="row">
            <div className="col-12">
              <h1 className="mb-2 text-uppercase">404</h1>
              <h2 className="mb-3 text-uppercase">Page Not Found</h2>
              <p>
                Sorry, the page you are looking for cannot be found. Try checking the URL or return
                back home.
              </p>
              <Link to="/" className="btn btn-primary" title="Learn about Just Pick Something">
                Return Home
              </Link>
            </div>
          </div>
        </PageBody>
      </Layout>
    );
  }
}

export default Error404;
