import 'swiper/swiper.scss';
import React from 'react';
import Swiper from 'react-id-swiper';
import appshot1 from '../../assets/images/app-shots/jps-screenshot-splash.png';
import appshot2 from '../../assets/images/app-shots/jps-screenshot-type.png';
import appshot3 from '../../assets/images/app-shots/jps-screenshot-locations.png';
import appshot4 from '../../assets/images/app-shots/jps-screenshot-establishment.png';
import appshot5 from '../../assets/images/app-shots/jps-screenshot-book.png';
import appshot6 from '../../assets/images/app-shots/jps-screenshot-movie.png';

interface Screenshot {
  src: string;
  alt: string;
}

const SCREENSHOTS: Screenshot[] = [
  {
    src: appshot1,
    alt: 'Just Pick Something App',
  },
  {
    src: appshot2,
    alt: 'Recommendations when staying in',
  },
  {
    src: appshot3,
    alt: 'Where should I go out',
  },
  {
    src: appshot4,
    alt: 'Best Restaurants near me',
  },
  {
    src: appshot5,
    alt: 'Best Book Recommendations for me',
  },
  {
    src: appshot6,
    alt: 'Best TV & Movie Recommendations for me',
  },
];

const ConsumerScreenshots: React.FC<{}> = () => {
  const params = {
    loop: true,
    speed: 1000,
    spaceBetween: 0,
    slidesPerView: 1,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    // Responsive breakpoints
    breakpoints: {
      575: {
        spaceBetween: 0,
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 3,
      },

      1024: {
        spaceBetween: 90,
        slidesPerView: 3,
      },

      1440: {
        spaceBetween: 290,
        slidesPerView: 3,
      },

      1499: {
        spaceBetween: 40,
        slidesPerView: 5,
      },
    },
  };

  return (
    <section className="app-shot-one" id="screenshots">
      <img src="/assets/images/background/app-shot-bg.png" alt="" className="app-shot-one__bg" />
      <div className="container-fluid">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Take a&nbsp;
            <span>sneakpeak</span>
            &nbsp;at what you are missing
          </h2>
        </div>
        <div className="app-shot-one__carousel">
          <Swiper {...params}>
            {SCREENSHOTS.map((screenshot: Screenshot) => (
              <div key={screenshot.src} className="item">
                <img src={screenshot.src} alt={screenshot.alt} />
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ConsumerScreenshots;
