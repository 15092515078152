import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Analytics, { PAGES } from '../analytics';
import { Layout, PageBody } from '../components';
import { DMCA_EMAIL } from '../constants';

class TermsConditions extends React.PureComponent<{}, never> {
  public componentDidMount(): void {
    Analytics.logPageview(PAGES.TERMS);
  }

  public render(): React.ReactNode {
    return (
      <Layout>
        <Helmet>
          <title>Terms and Conditions | Just Pick Something App</title>
          <meta
            name="description"
            content="Terms and Conditions for Just Pick Something (JPS) app. Last updated September 28, 2018"
          />
          <meta
            name="keywords"
            content="just pick something, jps,terms, terms of use, terms and conditions, user terms"
          />
        </Helmet>
        <PageBody noBanner>
          <div className="row">
            <div className="col-12">
              <h1>Terms and Conditions</h1>

              <p>Last updated: January 8, 2021</p>

              <p>
                Please read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and
                Conditions&quot;) carefully before using the https://www.justpicksomethingapp.com
                website and the Just Pick Something mobile application (together, or individually,
                the &quot;Service&quot;) operated by Just Pick Something, Inc. (&quot;us&quot;,
                &quot;we&quot;, or &quot;our&quot;).
              </p>

              <p>
                Your access to and use of the Service is conditioned upon your acceptance of and
                compliance with these Terms. These Terms apply to all visitors, users and others who
                wish to access or use the Service.
              </p>

              <p>
                By accessing or using the Service you agree to be bound by these Terms. If you
                disagree with any part of the terms then you do not have permission to access the
                Service.
              </p>

              <h2>Communications</h2>

              <p>
                By creating an Account on our service, you agree to subscribe to newsletters,
                marketing or promotional materials and other information we may send. However, you
                may opt out of receiving any, or all, of these communications from us by following
                the unsubscribe link or instructions provided in any email we send.
              </p>

              <h2>Subscriptions</h2>

              <p>
                Some parts of the Service are billed on a subscription basis
                (&quot;Subscription(s)&quot;). You will be billed in advance on a recurring and
                periodic basis (&quot;Billing Cycle&quot;). Billing cycles are set either on a
                monthly or annual basis, depending on the type of subscription plan you select when
                purchasing a Subscription.
              </p>

              <p>
                At the end of each Billing Cycle, your Subscription will automatically renew under
                the exact same conditions unless you cancel it or Just Pick Something, Inc. cancels
                it. You may cancel your Subscription renewal either through your online account
                management page or by contacting Just Pick Something, Inc. customer support team.
              </p>

              <p>
                A valid payment method, including credit card or PayPal, is required to process the
                payment for your Subscription. You shall provide Just Pick Something, Inc. with
                accurate and complete billing information including full name, address, state, zip
                code, telephone number, and a valid payment method information. By submitting such
                payment information, you automatically authorize Just Pick Something, Inc. to charge
                all Subscription fees incurred through your account to any such payment instruments.
              </p>

              <p>
                Should automatic billing fail to occur for any reason, Just Pick Something, Inc.
                will issue an electronic invoice indicating that you must proceed manually, within a
                certain deadline date, with the full payment corresponding to the billing period as
                indicated on the invoice.
              </p>

              <h2>Fee Changes</h2>

              <p>
                Just Pick Something, Inc., in its sole discretion and at any time, may modify the
                Subscription fees for the Subscriptions. Any Subscription fee change will become
                effective at the end of the then-current Billing Cycle.
              </p>

              <p>
                Just Pick Something, Inc. will provide you with a reasonable prior notice of any
                change in Subscription fees to give you an opportunity to terminate your
                Subscription before such change becomes effective.
              </p>

              <p>
                Your continued use of the Service after the Subscription fee change comes into
                effect constitutes your agreement to pay the modified Subscription fee amount.
              </p>

              <h2>Refunds</h2>

              <p>
                Certain refund requests for Subscriptions may be considered by Just Pick Something,
                Inc. on a case-by-case basis and granted in sole discretion of Just Pick Something,
                Inc..
              </p>

              <h2>Content</h2>

              <p>
                Our Service allows you to post, link, store, share and otherwise make available
                certain information, text, graphics, videos, or other material
                (&quot;Content&quot;). You are responsible for the Content that you post on or
                through the Service, including its legality, reliability, and appropriateness.
              </p>

              <p>
                By posting Content on or through the Service, You represent and warrant that: (i)
                the Content is yours (you own it) and/or you have the right to use it and the right
                to grant us the rights and license as provided in these Terms, and (ii) that the
                posting of your Content on or through the Service does not violate the privacy
                rights, publicity rights, copyrights, contract rights or any other rights of any
                person or entity. We reserve the right to terminate the account of anyone found to
                be infringing on a copyright.
              </p>

              <p>
                You retain any and all of your rights to any Content you submit, post or display on
                or through the Service and you are responsible for protecting those rights. We take
                no responsibility and assume no liability for Content you or any third party posts
                on or through the Service. However, by posting Content using the Service you grant
                us the right and license to use, modify, publicly perform, publicly display,
                reproduce, and distribute such Content on and through the Service. You agree that
                this license includes the right for us to make your Content available to other users
                of the Service, who may also use your Content subject to these Terms.
              </p>

              <p>
                Just Pick Something, Inc. has the right but not the obligation to monitor and edit
                all Content provided by users.
              </p>

              <p>
                In addition, Content found on or through this Service are the property of Just Pick
                Something, Inc. or used with permission. You may not distribute, modify, transmit,
                reuse, download, repost, copy, or use said Content, whether in whole or in part, for
                commercial purposes or for personal gain, without express advance written permission
                from us.
              </p>

              <h2>Accounts</h2>

              <p>
                When you create an account with us, you guarantee that you are above the age of 18,
                and that the information you provide us is accurate, complete, and current at all
                times. Inaccurate, incomplete, or obsolete information may result in the immediate
                termination of your account on the Service.
              </p>

              <p>
                You are responsible for maintaining the confidentiality of your account and
                password, including but not limited to the restriction of access to your computer
                and/or account. You agree to accept responsibility for any and all activities or
                actions that occur under your account and/or password, whether your password is with
                our Service or a third-party service. You must notify us immediately upon becoming
                aware of any breach of security or unauthorized use of your account.
              </p>

              <h2>Copyright Policy</h2>

              <p>
                We respect the intellectual property rights of others. It is our policy to respond
                to any claim that Content posted on the Service infringes on the copyright or other
                intellectual property rights (&quot;Infringement&quot;) of any person or entity.
              </p>

              <p>
                If you are a copyright owner, or authorized on behalf of one, and you believe that
                the copyrighted work has been copied in a way that constitutes copyright
                infringement, please submit your claim via email to dmca@justpicksomethingapp.com,
                with the subject line: &quot;Copyright Infringement&quot; and include in your claim
                a detailed description of the alleged Infringement as detailed below, under
                &quot;DMCA Notice and Procedure for Copyright Infringement Claims&quot;
              </p>

              <p>
                You may be held accountable for damages (including costs and attorneys&apos; fees)
                for misrepresentation or bad-faith claims on the infringement of any Content found
                on and/or through the Service on your copyright.
              </p>

              <h2>DMCA Notice and Procedure for Copyright Infringement Claims</h2>

              <p>
                You may submit a notification pursuant to the Digital Millennium Copyright Act
                (DMCA) by providing our Copyright Agent with the following information in writing
                (see 17 U.S.C 512(c)(3) for further detail):
              </p>

              <ul>
                <li>
                  <p>
                    an electronic or physical signature of the person authorized to act on behalf of
                    the owner of the copyright&apos;s interest;
                  </p>
                </li>
                <li>
                  <p>
                    a description of the copyrighted work that you claim has been infringed,
                    including the URL (i.e., web page address) of the location where the copyrighted
                    work exists or a copy of the copyrighted work;
                  </p>
                </li>
                <li>
                  <p>
                    identification of the URL or other specific location on the Service where the
                    material that you claim is infringing is located;
                  </p>
                </li>
                <li>
                  <p>your address, telephone number, and email address;</p>
                </li>
                <li>
                  <p>
                    a statement by you that you have a good faith belief that the disputed use is
                    not authorized by the copyright owner, its agent, or the law;
                  </p>
                </li>
                <li>
                  <p>
                    a statement by you, made under penalty of perjury, that the above information in
                    your notice is accurate and that you are the copyright owner or authorized to
                    act on the copyright owner&apos;s behalf.
                  </p>
                </li>
              </ul>

              <p>
                You can contact our Copyright Agent via email at&nbsp;
                <a href={DMCA_EMAIL.link} title={DMCA_EMAIL.text}>
                  {DMCA_EMAIL.email}
                </a>
                .
              </p>

              <h2>Intellectual Property</h2>

              <p>
                The Service and its original content (excluding Content provided by users), features
                and functionality are and will remain the exclusive property of Just Pick Something,
                Inc. and its licensors. The Service is protected by copyright, trademark, and other
                laws of both the United States and foreign countries. Our trademarks and trade dress
                may not be used in connection with any product or service without the prior written
                consent of Just Pick Something, Inc..
              </p>

              <h2>Links To Other Web Sites</h2>

              <p>
                Our Service may contain links to third party web sites or services that are not
                owned or controlled by Just Pick Something, Inc.
              </p>

              <p>
                Just Pick Something, Inc. has no control over, and assumes no responsibility for the
                content, privacy policies, or practices of any third party web sites or services. We
                do not warrant the offerings of any of these entities/individuals or their websites.
              </p>

              <p>
                You acknowledge and agree that Just Pick Something, Inc. shall not be responsible or
                liable, directly or indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such content, goods or
                services available on or through any such third party web sites or services.
              </p>

              <p>
                We strongly advise you to read the terms and conditions and privacy policies of any
                third party web sites or services that you visit.
              </p>

              <h2>Termination</h2>

              <p>
                We may terminate or suspend your account and bar access to the Service immediately,
                without prior notice or liability, under our sole discretion, for any reason
                whatsoever and without limitation, including but not limited to a breach of the
                Terms.
              </p>

              <p>
                If you wish to terminate your account, you may simply discontinue using the Service.
              </p>

              <p>
                All provisions of the Terms which by their nature should survive termination shall
                survive termination, including, without limitation, ownership provisions, warranty
                disclaimers, indemnity and limitations of liability.
              </p>

              <h2>Indemnification</h2>

              <p>
                You agree to defend, indemnify and hold harmless Just Pick Something, Inc. and its
                licensee and licensors, and their employees, contractors, agents, officers and
                directors, from and against any and all claims, damages, obligations, losses,
                liabilities, costs or debt, and expenses (including but not limited to
                attorney&apos;s fees), resulting from or arising out of a) your use and access of
                the Service, by you or any person using your account and password; b) a breach of
                these Terms, or c) Content posted on the Service.
              </p>

              <h2>Limitation Of Liability</h2>

              <p>
                In no event shall Just Pick Something, Inc., nor its directors, employees, partners,
                agents, suppliers, or affiliates, be liable for any indirect, incidental, special,
                consequential or punitive damages, including without limitation, loss of profits,
                data, use, goodwill, or other intangible losses, resulting from (i) your access to
                or use of or inability to access or use the Service; (ii) any conduct or content of
                any third party on the Service; (iii) any content obtained from the Service; and
                (iv) unauthorized access, use or alteration of your transmissions or content,
                whether based on warranty, contract, tort (including negligence) or any other legal
                theory, whether or not we have been informed of the possibility of such damage, and
                even if a remedy set forth herein is found to have failed of its essential purpose.
              </p>

              <h2>Disclaimer</h2>

              <p>
                Your use of the Service is at your sole risk. The Service is provided on an &quot;AS
                IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without
                warranties of any kind, whether express or implied, including, but not limited to,
                implied warranties of merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>

              <p>
                Just Pick Something, Inc. its subsidiaries, affiliates, and its licensors do not
                warrant that a) the Service will function uninterrupted, secure or available at any
                particular time or location; b) any errors or defects will be corrected; c) the
                Service is free of viruses or other harmful components; or d) the results of using
                the Service will meet your requirements.
              </p>

              <h2>Exclusions</h2>

              <p>
                Some jurisdictions do not allow the exclusion of certain warranties or the exclusion
                or limitation of liability for consequential or incidental damages, so the
                limitations above may not apply to you.
              </p>

              <h2>Governing Law</h2>

              <p>
                These Terms shall be governed and construed in accordance with the laws of Illinois,
                United States, without regard to its conflict of law provisions.
              </p>

              <p>
                Our failure to enforce any right or provision of these Terms will not be considered
                a waiver of those rights. If any provision of these Terms is held to be invalid or
                unenforceable by a court, the remaining provisions of these Terms will remain in
                effect. These Terms constitute the entire agreement between us regarding our
                Service, and supersede and replace any prior agreements we might have had between us
                regarding the Service.
              </p>

              <h2>Changes</h2>

              <p>
                We reserve the right, at our sole discretion, to modify or replace these Terms at
                any time. If a revision is material we will provide at least 30 days notice prior to
                any new terms taking effect. What constitutes a material change will be determined
                at our sole discretion.
              </p>

              <p>
                By continuing to access or use our Service after any revisions become effective, you
                agree to be bound by the revised terms. If you do not agree to the new terms, you
                are no longer authorized to use the Service.
              </p>

              <h2>Contact Us</h2>

              <p>
                If you have any questions about these Terms,&nbsp;
                <Link to="/help" title="Contact Just Pick Something">
                  please contact us
                </Link>
                .
              </p>
            </div>
          </div>
        </PageBody>
      </Layout>
    );
  }
}

export default TermsConditions;
