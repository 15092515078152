import React from 'react';
import { SectionProps } from '../../types';

type BusinessCallToActionTwoProps = SectionProps;

const BusinessCallToActionTwo: React.FC<BusinessCallToActionTwoProps> = ({ sectionId }) => {
  return (
    <section className="cta-two" id={sectionId}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  We are&nbsp;
                  <span>different</span>
                  &nbsp;- we care
                </h2>
              </div>
              <div className="cta-two__text">
                <p>
                  We are not going to be another&nbsp;
                  <strong>
                    <u>&nbsp;&nbsp;(INSERT A$*HOLE COMPANY HERE)&nbsp;&nbsp;</u>
                  </strong>
                  . We are not here to force you to pay for &quot;premium advertisement spots&quot;
                  or have you pay to remove those fake reviews - we don&apos;t even do reviews. We
                  are here to work with customers and businesses and help create amazing expeirences
                  between them.
                </p>
              </div>
              <div className="row">
                <div className="col-12 pb-4 col-lg-6 pr-lg-0 pb-lg-0">
                  <a
                    href="#pricing"
                    title="Sign up my business for Just Pick Something"
                    className="thm-btn text-center"
                  >
                    <span>Sign up now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessCallToActionTwo;
