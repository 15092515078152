import React from 'react';
import Analytics, { EVENTS } from '../../analytics';
import mocImage from '../../assets/images/mocs/cta-moc-2-1.png';
import { APP_DOWNLOAD_URLS } from '../../constants';
import { SectionProps } from '../../types';

type CallToActionTwoProps = SectionProps;

const CallToActionTwo: React.FC<CallToActionTwoProps> = ({ sectionId }) => {
  return (
    <section className="cta-two" id={sectionId}>
      <div className="container">
        <img src={mocImage} className="cta-two__moc" alt="" />
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <i className="cta-two__icon dimon-icon-data" />
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  What are&nbsp;
                  <span>you</span>
                  &nbsp;waiting for?
                </h2>
              </div>
              <div className="cta-two__text">
                <p>
                  Join our loyal fans and start discovering experiences that change their lives.
                  From finding the perfect date spot to how to stay sane with the kids, we have it
                  all curated just for your personality.
                </p>
                <p>
                  Get ready to discover amazing experiences faster than it took for you to decide to
                  download Just Pick Something.
                </p>
              </div>
              <div className="row">
                <div className="col-12 pb-4">
                  <a
                    href={APP_DOWNLOAD_URLS.android.link}
                    title="Download the best discovery app for Android"
                    className="thm-btn text-center"
                    onClick={() => {
                      Analytics.logEvent(EVENTS.CLICK_APP_DOWNLOAD, {
                        app: 'android',
                        text: 'Download for Android',
                        style: 'button',
                        source: 'home download section',
                      });
                    }}
                  >
                    <span>
                      <i className="fab fa-google-play" />
                      &nbsp;Download on Android
                    </span>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    href={APP_DOWNLOAD_URLS.ios.link}
                    title="Download the best discovery app for iOS"
                    className="thm-btn text-center"
                    onClick={() => {
                      Analytics.logEvent(EVENTS.CLICK_APP_DOWNLOAD, {
                        app: 'ios',
                        text: 'Download for iOS',
                        style: 'button',
                        source: 'home download section',
                      });
                    }}
                  >
                    <span>
                      <i className="fab fa-app-store-ios" />
                      &nbsp;Download on iOS
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionTwo;
