import React from 'react';
import Helmet from 'react-helmet';
import Banner from './sections/ConsumerBanner';
import CallToAction from './sections/CallToAction';
import CallToActionTwo from './sections/CallToActionTwo';
import Stats from './sections/ConsumerStats';
import Screenshots from './sections/ConsumerScreenshots';
import Features from './sections/ConsumerFeatures';
import { Layout, Newsletter } from '../components';
import { PageNavLinks } from '../types';

const CONSUMER_NAV_LINKS: PageNavLinks = [
  {
    link: '#features',
    title: 'Features of Just Pick Something',
    text: 'Features',
    tag: 'normal',
  },
  {
    link: '#screenshots',
    title: 'Sneakpeak at Just Pick Something',
    text: 'Sneakpeak',
    tag: 'normal',
  },
];

const ConsumerLandingPage: React.FC<{}> = () => (
  <Layout pageNavLinks={CONSUMER_NAV_LINKS} actionButtonType="download">
    <Helmet>
      <title>Just Pick Something App | Learn about and download</title>
      <meta
        name="description"
        content="Discover new restaurants, bars and experiences in your city - fast, simple, easy"
      />
      <meta
        name="keywords"
        content="just pick something, jps, app, download, learn, discover, city, restaurants, bars, concerts, experiences, sports, tickets"
      />
    </Helmet>
    <Banner />
    <Features />
    <CallToAction />
    <CallToActionTwo sectionId="download" />
    <Stats />
    <Screenshots />
    <Newsletter source="consumer" />
  </Layout>
);

export default ConsumerLandingPage;
