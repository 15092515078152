import React from 'react';
import Helmet from 'react-helmet';
import Analytics, { PAGES } from '../analytics';
import { SUPPORT_EMAIL } from '../constants';
import { Layout, PageBody } from '../components';

interface Error500Props {
  withHeader?: boolean;
}

class Error500 extends React.PureComponent<Error500Props, never> {
  public componentDidMount(): void {
    Analytics.logPageview(PAGES.ERROR_500);
  }

  public render(): React.ReactNode {
    const { withHeader } = this.props;

    return (
      <Layout hideHeader={!withHeader}>
        <Helmet>
          <title>Error page</title>
          <meta
            name="description"
            content="There was a problem with rendering Just Pick Something"
          />
          <meta name="keywords" content="just pick something, jps, error page, 500 error" />
          <meta httpEquiv="Status" content="500 Internal Server Error" />
        </Helmet>
        <PageBody noBanner>
          <div className="row">
            <div className="col-12">
              <h1 className="mb-2 text-uppercase">500</h1>
              <h2 className="mb-3 text-uppercase">Yup, That&apos;s An Error</h2>
              <p>Um, something went wrong, but rest assured that we&apos;re working on it.</p>
              <a
                href={SUPPORT_EMAIL.link}
                title={SUPPORT_EMAIL.text}
                className="btn btn-outline-info"
              >
                <span className="fas fa-envelope" />
                &nbsp;Need to reach us immediately? Just send us an email!
              </a>
            </div>
          </div>
        </PageBody>
      </Layout>
    );
  }
}

export default Error500;
