import React from 'react';
import Helmet from 'react-helmet';
import Analytics, { PAGES, EVENTS } from '../analytics';
import { Layout, PageHeader, PageBody, Newsletter } from '../components';
import { SUPPORT_EMAIL } from '../constants';

export default class AboutPage extends React.PureComponent<{}, never> {
  public componentDidMount(): void {
    Analytics.logPageview(PAGES.ABOUT);
  }

  private renderContent(): React.ReactNode {
    return (
      <PageBody>
        <div className="row">
          <h1>What is our story</h1>
          <p>
            A nerdy college kid, with way too many projects going on already, came up with a new,
            brilliant idea. He wanted to create a list of hot spots that would be useful to his
            peers. So, he spent the spring and summer typing away,&nbsp;
            <strong>adding data manually</strong>
            &nbsp; to a MySQL database. He came up with a cool design for the website and ideas to
            integrate into third-party platforms. It was going to be amazing! Then, he got bored
            with the idea and moved on.
          </p>
          <p>
            Fast forward to post-college life. He started working at some amazing technology
            companies in Chicago, only to realize how&nbsp;
            <strong>frustrating it was to find a place to go on a date!</strong>
            &nbsp;The idea from college was stuck in his head, day and night. Then, in August of
            2017, it happened. After days of brainstorming names, we landed on&nbsp;
            <strong>Just Pick Something Inc.</strong>
          </p>
          <p>
            Since then, we have been working hard to come up with a product consumers will love and
            a business solution our partners can use to solve problems. We hope you will try Just
            Pick Something and let us know what you think! Thank you for everything&nbsp;
            <strong>you</strong>
            &nbsp; do.
          </p>
          <h2>Why do we care</h2>
          <p>
            Our initial goal was to help consumers stop wasting time digging through long lists of
            irrelevant results, just to find a place to go eat or the next bar on their way home. It
            was slow, boring and a waste of time. But, when digging into the data, we became
            intrgued and determined to help business owners make their dream a reality.
          </p>
          <p>
            Hundreds of thousands of restaurants and bars open in the United States every
            year.&nbsp;
            <strong>59% of them will fail within the first three years.</strong>
            &nbsp;That is why we are making it our duty to help these family and friend operated
            businesses.
          </p>
          <h2>Who the hell are they</h2>
          <p>
            There are currently three of us, two nerdy developers who love programming to much and
            some smart international business dude,&nbsp;
            <i>who does some stuff or whatever.</i>
          </p>
          <figure className="text-center">
            <img
              src="/images/mobile-application/generic/jps-founders.jpg"
              alt="Just Pick Something Founders"
            />
            <figcaption className="text-left font-weight-light mt-2">
              To put names to the handsome faces you see about, from left to right: Jim Kenevan
              (CMO), James Dobry (CEO), David Beidrzycki (CTO).
              <br />
              *And no, Jim and James did not change names to make this easy, but we kind of wish
              David would switch his.
            </figcaption>
          </figure>
          <p>
            If you asked us, we would probably say we are best friends to one another. Jim and James
            met Freshman year in college at Illinois State University. David met James when they
            both started working at the same company in Chicago, right out of college. Since then,
            they let shared interests like racing cars and trying to modify them, only to break
            them, bring all three closer together.
          </p>
          <p>
            We are always looking for amazing and talented people to help us build a better world
            (cheesy, we know). So, if you want to sit down and have a quick chat over coffee or
            email, just shoot us a quick one at&nbsp;
            <a
              href={SUPPORT_EMAIL.link}
              title="Email Just Pick Something's People Team"
              onClick={() => {
                Analytics.logEvent(EVENTS.CLICK_EXTERNAL_LINK, {
                  to: SUPPORT_EMAIL.link,
                  text: SUPPORT_EMAIL.email,
                  style: 'link',
                  source: 'about',
                });
              }}
            >
              {SUPPORT_EMAIL.email}
            </a>
            .
          </p>
          <h2>Become our friend</h2>
          <p>
            We are always looking for new friends, especially since we don&apos;t have many - not my
            fault everyone&nbsp;
            <i>else</i>
            &nbsp;is weird. Feel free to shoot us an email&nbsp;(
            <a
              href={SUPPORT_EMAIL.link}
              title="Email Just Pick Something's People Team"
              onClick={() => {
                Analytics.logEvent(EVENTS.CLICK_EXTERNAL_LINK, {
                  to: SUPPORT_EMAIL.link,
                  text: SUPPORT_EMAIL.email,
                  style: 'link',
                  source: 'about',
                });
              }}
            >
              {SUPPORT_EMAIL.email}
            </a>
            ), sign up for a newsletter or follow us on our pretty cool social media accounts, all
            of that is below - all the cool kids are doing it.
          </p>
        </div>
      </PageBody>
    );
  }

  public render(): React.ReactNode {
    return (
      <Layout navStyle="dark">
        <Helmet>
          <title>The story and people behind Just Pick Something Inc.</title>
          <meta
            name="description"
            content="Learn the story about the people who make Just Pick Something and what they are all about"
          />
          <meta name="keywords" content="just pick something, jps, about, founders, creators" />
        </Helmet>
        <PageHeader title="About Just Pick Something" />
        {this.renderContent()}
        <Newsletter source="about" />
      </Layout>
    );
  }
}
