import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BreadcrumbList } from '../types';

interface PageHeaderProps {
  title: string;
  breadcrumbs?: BreadcrumbList;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, breadcrumbs = [] }) => (
  <section className="inner-banner">
    <span className="banner-two__shape-1" />
    <span className="banner-two__shape-2" />
    <span className="banner-two__shape-3" />
    <span className="banner-two__shape-4" />
    <div className="container">
      {breadcrumbs.length > 0 && (
        <ul className="list-unstyled thm-breadcrumb">
          {breadcrumbs.map(breadcrumb => {
            const isCurrentPage = false;
            const classes = classNames('scrollToLink', {
              active: isCurrentPage,
            });

            return (
              <li key={breadcrumb.text} className={classes}>
                {breadcrumb.tag === 'normal' ? (
                  <a href={breadcrumb.link as string} title={breadcrumb.title ?? breadcrumb.text}>
                    {breadcrumb.text}
                  </a>
                ) : (
                  <Link to={breadcrumb.link} title={breadcrumb.title ?? breadcrumb.text}>
                    {breadcrumb.text}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      )}
      <h2 className="inner-banner__title" data-aos="fade-up">
        {title}
      </h2>
    </div>
  </section>
);

export default PageHeader;
