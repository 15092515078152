import React from 'react';
import { Features } from '../../components';
import { FeatureList } from '../../types';

const FEATURES: FeatureList = [
  {
    title: 'Analyze trends',
    text: 'Learn about consumer demand around you and how to capitalize on it',
    icon: 'grin-hearts',
  },
  {
    title: 'Event management',
    text: 'Manage and optimize your event coverage.',
    icon: 'ticket-alt',
  },
  {
    title: 'Beat the competition',
    text: 'Being in your industry is tough, but we know the tricks to help',
    icon: 'trophy',
  },
  {
    title: 'Location management',
    text: 'Easily keep your locations up-to-date',
    icon: 'building',
  },
];

const BusinessFeatures: React.FC<{}> = () => {
  return (
    <Features
      sectionTitle={(
        <>
          The features that&nbsp;
          <span>you</span>
          &nbsp;have needed
        </>
      )}
      features={FEATURES}
    />
  );
};

export default BusinessFeatures;
