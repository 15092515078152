export enum EVENTS {
  CLICK_APP_DOWNLOAD = 'CLICK_APP_DOWNLOAD',
  CLICK_EXTERNAL_LINK = 'CLICK_EXTERNAL_LINK',
  CLICK_BUTTON = 'CLICK_BUTTON',
  CLICK_NAV_LINK = 'CLICK_NAV_LINK',
  NEWSLETTER_EMAIL_CHANGE = 'NEWSLETTER_EMAIL_CHANGE',
  NEWSLETTER_SIGNUP = 'NEWSLETTER_SIGNUP',
  BUSINESS_PRICING_SIGNUP = 'BUSINESS_PRICING_SIGNUP',
  BUSINESS_PRICING_SIGNUP_SUBMIT = 'BUSINESS_PRICING_SIGNUP_SUBMIT',
  BUSINESS_PRICING_SIGNUP_CLOSE = 'BUSINESS_PRICING_SIGNUP_CLOSE',
}

export enum PAGES {
  CONSUMERS = 'CONSUMERS',
  BUSINESSES = 'BUSINESSES',
  ABOUT = 'ABOUT',
  TERMS = 'TERMS',
  PRIVACY = 'PRIVACY',
  HELP = 'HELP',
  ERROR_500 = 'ERROR_500',
  ERROR_404 = 'ERROR_404',
  SECTION_FEATURES = 'SECTION_FEATURES',
  SECTION_PRICING = 'SECTION_PRICING',
  SECTION_SNEAKPEAK = 'SECTION_SNEAKPEAK',
  SECTION_NEWSLETTER = 'SECTION_NEWSLETTER',
}
