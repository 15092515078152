import React from 'react';
import classNames from 'classnames';
import './PageBody.scss';

interface PageBodyProps {
  noBanner?: boolean;
}

const PageBody: React.FC<PageBodyProps> = ({ noBanner, children }) => (
  <section
    className={classNames('page-body', {
      'page-body__no-banner': noBanner,
      'py-5': !noBanner,
    })}
  >
    <div className="container">{children}</div>
  </section>
);

export default PageBody;
