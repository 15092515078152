import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import darkLogo from '../../assets/images/resources/logo-dark.png';
import { MAIN_EMAIL, APP_DOWNLOAD_URLS, SOCIAL_NETWORK_URLS } from '../../constants';

interface BlogState {
  scrollBtn: boolean;
}

export default class Blog extends Component<{}, BlogState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      scrollBtn: false,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll = (): void => {
    const showScrollBtn = window.scrollY > 70;

    this.setState({
      scrollBtn: showScrollBtn,
    });
  };

  private handleScrollTop = (): void => {
    window.scrollTo(0, 0);
  };

  public render(): React.ReactNode {
    const { scrollBtn } = this.state;

    return (
      <>
        <footer className="site-footer">
          <div className="site-footer__upper">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 mb-4 mb-xl-0">
                  <div className="footer-widget footer-widget__about">
                    <img
                      src={darkLogo}
                      width="119"
                      alt="Just Pick Something, Inc."
                      className="footer-widget__logo"
                    />
                    <p className="footer-widget__contact">
                      <Link to={MAIN_EMAIL.link} title={MAIN_EMAIL.text}>
                        {MAIN_EMAIL.email}
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 d-flex justify-content-between footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Download JPS</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <Link
                          to={APP_DOWNLOAD_URLS.android.link}
                          title={APP_DOWNLOAD_URLS.android.text}
                        >
                          Download on Android
                        </Link>
                      </li>
                      <li>
                        <Link to={APP_DOWNLOAD_URLS.ios.link} title={APP_DOWNLOAD_URLS.ios.text}>
                          Download on iOS
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Choose your flavor</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <Link to="/" title="Just Pick Something App">
                          For consumers
                        </Link>
                      </li>
                      <li>
                        <Link to="/businesses" title="Just Pick Something for Businesses">
                          For businesses
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Helpful links</h3>
                    <ul className="footer-widget__links list-unstyled">
                      <li>
                        <Link to="/about" title="About Just Pick Something">
                          Who are we?
                        </Link>
                      </li>
                      <li>
                        <Link to="/help" title="Just Pick Something Support">
                          I need help
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms" title="Terms of Use">
                          Terms of Use
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy" title="Privacy Policy">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="footer-widget">
                    <div className="site-footer__social">
                      <Link
                        to={SOCIAL_NETWORK_URLS.facebook.link}
                        title={SOCIAL_NETWORK_URLS.facebook.text}
                        className="facebook"
                        aria-label="social icon"
                        aria-hidden="true"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link
                        to={SOCIAL_NETWORK_URLS.twitter.link}
                        title={SOCIAL_NETWORK_URLS.twitter.text}
                        className="twitter"
                        aria-label="social icon"
                        aria-hidden="true"
                      >
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link
                        to={SOCIAL_NETWORK_URLS.instagram.link}
                        title={SOCIAL_NETWORK_URLS.instagram.text}
                        className="instagram"
                        aria-label="social icon"
                        aria-hidden="true"
                      >
                        <i className="fab fa-instagram" />
                      </Link>
                      <Link
                        to={SOCIAL_NETWORK_URLS.youtube.link}
                        title={SOCIAL_NETWORK_URLS.youtube.text}
                        className="youtube"
                        aria-label="social icon"
                        aria-hidden="true"
                      >
                        <i className="fab fa-youtube" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="inner-container text-center">
                <p className="site-footer__copy">
                  &copy; Just Pick Something, Inc. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <div
          onClick={this.handleScrollTop}
          onKeyDown={this.handleScrollTop}
          role="button"
          tabIndex={0}
          className="scroll-to-target scroll-to-top"
          style={{ display: scrollBtn ? 'block' : 'none' }}
        >
          <i className="fas fa-angle-up" />
        </div>
      </>
    );
  }
}
