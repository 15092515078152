import React, { Component } from 'react';
import { PricingPlan, PricingPlans } from '../types';

interface PricingProps {
  sectionTitle: React.ReactNode;
  sectionText: React.ReactNode;
  pricingPlans: PricingPlans;
  onPlanSelect(plan: PricingPlan, paymentPeriod: 'monthly' | 'yearly'): void;
}

interface PricingState {
  month: boolean;
  year: boolean;
}

export default class Pricing extends Component<PricingProps, PricingState> {
  constructor(props: PricingProps) {
    super(props);

    this.state = {
      month: true,
      year: false,
    };
  }

  private handleToggle = (): void => {
    const { month, year } = this.state;

    this.setState({
      year: month,
      month: year,
    });
  };

  public render(): React.ReactNode {
    const { sectionTitle, sectionText, pricingPlans, onPlanSelect } = this.props;
    const { month, year } = this.state;

    const columnLength = Math.round(12 / pricingPlans.length);

    return (
      <section className="pricing-one" id="pricing">
        <div className="container">
          <div className="block-title text-center">
            <h2 className="block-title__title">{sectionTitle}</h2>
          </div>
          {sectionText && <div className="cta-one__text text-center mb-4">{sectionText}</div>}
          <ul className="list-inline text-center switch-toggler-list" id="switch-toggle-tab">
            <li className={month ? 'month active' : 'month'}>
              <span className="ml-4">Monthly</span>
            </li>
            <li>
              <div
                className={year ? 'switch off' : 'switch on'}
                role="button"
                tabIndex={-1}
                onClick={this.handleToggle}
                onKeyDown={this.handleToggle}
              >
                <span className="slider round" />
              </div>
            </li>
            <li className={year ? 'year active' : 'year'}>
              <span className="mr-4">Yearly</span>
            </li>
          </ul>
          <div className="tabed-content">
            {month === true ? (
              <div id="month">
                <div className="row pricing-one__price-row">
                  {pricingPlans.map(plan => (
                    <div
                      key={plan.title}
                      className={`col-lg-${columnLength}`}
                      data-aos="fade-up"
                      data-aos-duration="1500ms"
                    >
                      <div className="pricing-one__single text-center">
                        <h3>{plan.costMonthly}</h3>
                        <p>{plan.title}</p>
                        <ul className="list-unstyled">
                          {plan.features.map(feature => (
                            <li key={feature}>{feature}</li>
                          ))}
                        </ul>
                        <button
                          type="button"
                          onClick={() => onPlanSelect(plan, 'monthly')}
                          title={`Select ${plan.title} payment plan`}
                          className="thm-btn pricing-one__btn"
                        >
                          <span>Choose Plan</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {year === true ? (
              <div id="year">
                <div className="row pricing-one__price-row">
                  {pricingPlans.map(plan => (
                    <div
                      key={plan.title}
                      className={`col-lg-${columnLength}`}
                      data-aos="fade-up"
                      data-aos-duration="1500ms"
                    >
                      <div className="pricing-one__single text-center">
                        <h3>{plan.costYearly}</h3>
                        <p>{plan.title}</p>
                        {plan.savings && (
                          <span className="pricing-one__single__savings">{plan.savings}</span>
                        )}
                        <ul className="list-unstyled">
                          {plan.features.map(feature => (
                            <li key={feature}>{feature}</li>
                          ))}
                        </ul>
                        <button
                          type="button"
                          onClick={() => onPlanSelect(plan, 'yearly')}
                          title={`Select ${plan.title} payment plan`}
                          className="thm-btn pricing-one__btn"
                        >
                          <span>Choose Plan</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}
