import React from 'react';
import Analytics, { EVENTS } from '../analytics';

interface NewsletterProps {
  source: string;
}

const Newsletter: React.FC<NewsletterProps> = ({ source }) => {
  return (
    <section className="mailchimp-one" id="newsletter">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            We always keep our&nbsp;
            <span>family</span>
            &nbsp;updated
          </h2>
        </div>
        <form
          action="https://justpicksomethingapp.us18.list-manage.com/subscribe/post?u=708fa512e4e9d96b8e5db28ba&amp;id=56a1fdefb3"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          className="mailchimp-one__mc-form mc-form"
          onSubmit={() => {
            Analytics.logEvent(EVENTS.NEWSLETTER_SIGNUP, {
              source,
            });
          }}
          noValidate
        >
          <label htmlFor="mce-EMAIL" className="sr-only">
            Add email
          </label>
          <input
            type="email"
            className="formInput"
            id="mce-EMAIL"
            aria-describedby="subscribe"
            name="EMAIL"
            placeholder="Email address"
            tabIndex={0}
            required
            onChange={() => {
              Analytics.logEvent(EVENTS.NEWSLETTER_EMAIL_CHANGE, {
                source,
              });
            }}
          />
          <button type="submit">
            <i className="fas fa-arrow-right" />
          </button>
        </form>
        <div className="mc-form__response" />
      </div>
    </section>
  );
};

export default Newsletter;
