import React from 'react';
import { Link } from 'react-router-dom';
import { FeatureList } from '../types';

interface FeaturesProps {
  sectionTitle: React.ReactNode;
  features: FeatureList;
}

const Features: React.FC<FeaturesProps> = ({ sectionTitle, features }) => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">{sectionTitle}</h2>
        </div>
        <div className="row">
          {features.map(feature => {
            const title = feature.link ? (
              <Link to={feature.link} title={feature.linkTitle ?? feature.title}>
                {feature.title}
              </Link>
            ) : (
              feature.title
            );

            return (
              <div
                key={feature.title}
                className="col-lg-3 col-md-6 col-sm-12"
                data-aos="fade-up"
                data-aos-duration="1500ms"
              >
                <div className="service-one__single text-center">
                  <div className="service-one__inner">
                    <i className={`service-one__icon fas fa-${feature.icon}`} />
                    <h3>{title}</h3>
                    <p>{feature.text}</p>
                    {feature.link && (
                      <Link
                        to={feature.link}
                        title={feature.linkTitle ?? feature.title}
                        className="service-one__link"
                      >
                        <i className="fas fa-arrow-right" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;
