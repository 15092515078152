import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { PageNavLinks, NavStyle, ActionButtonType } from '../../types';

interface LayoutProps {
  pageNavLinks?: PageNavLinks;
  navStyle?: NavStyle;
  actionButtonType?: ActionButtonType;
  hideHeader?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  pageNavLinks,
  navStyle,
  actionButtonType,
  hideHeader,
  children,
}) => (
  <div className="page-wrapper">
    {!hideHeader && (
      <Header pageNavLinks={pageNavLinks} navStyle={navStyle} actionButtonType={actionButtonType} />
    )}
    {children}
    <Footer />
  </div>
);

export default Layout;
